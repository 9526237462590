import { SpinViewer } from '@egjs/view360';
import Annotator from '../annotator';
const heartSprite = require('../../img/heart.jpg');

// Small devices (landscape phones, 576px and up)
// Medium devices (tablets, 768px and up)
// Large devices (desktops, 992px and up)
// Extra large devices (large desktops, 1200px and up)

document.addEventListener('DOMContentLoaded', () => {
    let heart = document.getElementById('heart');
    if (heart) {
        let heartAnnotations = new Annotator(heart, {
            obereHohlvene: {
                html: '<p>Obere Hohlvene</p>',
                classes: ['anot-right'],
                anker: 'bottom-right',
                x: [
                    {
                        type: 'linear',
                        from: 57.5,
                        to: 58,
                        times: 2,
                        suffix: '%',
                    },
                    { type: 'repeat', value: '59%', times: 2 },
                    {
                        type: 'linear',
                        from: 57.5,
                        to: 55.5,
                        times: 4,
                        suffix: '%',
                    },
                    null,
                ],
                y: ['70%'],
            },
            /*lungenVenen: {
                html: '<p>Lungenvenen</p>',
                classes: ['anot-right'],
                anker: 'bottom-right',
                x: [
                    null,
                    { type: 'repeat', value: '65%', times: 2 },
                    { type: 'linear', from: 65, to: 59, times: 6, suffix: '%' },
                    null,
                ],
                y: ['58%'],
            },*/
            rechterVorhof: {
                html: '<p>Rechter Vorhof</p>',
                classes: ['anot-right'],
                anker: 'bottom-right',
                x: [
                    { type: 'linear', from: 62, to: 42, times: 14, suffix: '%' },
                    { type: 'repeat', value: null, times: 28 },
                ],
                y: ['45%'],
            },
            /*trikuspidalKlappe: {
                html: '<p>Trikuspidalklappe</p>',
                classes: ['anot-right'],
                anker: 'bottom-right',
                x: [
                    { type: 'linear', from: 59, to: 58, times: 2, suffix: '%' },
                    { type: 'repeat', value: null, times: 25 },
                    { type: 'linear', from: 53, to: 57, times: 4, suffix: '%' },
                    { type: 'linear', from: 57, to: 59, times: 1, suffix: '%' },
                ],
                y: ['37%'],
            },*/
            rechteHerzkammer: {
                html: '<p>Rechte Herzkammer</p>',
                classes: ['anot-right'],
                anker: 'bottom-right',
                x: [
                    { type: 'linear', from: 52, to: 49, times: 5, suffix: '%' },
                    { type: 'repeat', value: null, times: 23 },
                ],
                y: ['24%'],
            },
            /*herzkrankzGefaess: {
                html: '<p>Herzkrankgefäß</p>',
                classes: ['anot-right'],
                anker: 'bottom-right',
                x: [
                    { type: 'linear', from: 52, to: 46, times: 4, suffix: '%' },
                    { type: 'repeat', value: null, times: 23 },
                    { type: 'linear', from: 56, to: 52, times: 9, suffix: '%' },
                ],
                y: ['17%'],
            },*/
            /*pulmonalKlappe: {
                html: '<p>Pulmonal Klappe</p>',
                classes: ['anot-right'],
                anker: 'bottom-right',
                x: [
                    { type: 'linear', from: 51, to: 48, times: 8, suffix: '%' },
                    { type: 'repeat', value: null, times: 20 },
                    { type: 'linear', from: 53, to: 53, times: 4, suffix: '%' },
                ],
                y: ['55%'],
            },*/
            aorta: {
                html: '<p>Aorta</p>',
                classes: ['anot-left'],
                anker: 'bottom-left',
                x: [
                    {
                        type: 'linear',
                        from: 53,
                        to: 49,
                        times: 4,
                        suffix: '%',
                    },
                    { type: 'repeat', value: null, times: 7 },
                    {
                        type: 'linear',
                        from: 44,
                        to: 57,
                        times: 23,
                        suffix: '%',
                    },
                ],
                y: ['80%'],
            },
            lungenArterie: {
                html: '<p>Lungenarterie</p>',
                classes: ['anot-left'],
                anker: 'bottom-left',
                x: [
                    {
                        type: 'linear',
                        from: 58,
                        to: 53,
                        times: 4,
                        suffix: '%',
                    },
                    { type: 'repeat', value: null, times: 10 },
                    {
                        type: 'linear',
                        from: 38,
                        to: 56,
                        times: 10,
                        suffix: '%',
                    },
                    {
                        type: 'linear',
                        from: 56,
                        to: 61,
                        times: 8,
                        suffix: '%',
                    },
                    {
                        type: 'linear',
                        from: 61,
                        to: 58,
                        times: 2,
                        suffix: '%',
                    },
                ],
                y: ['65%'],
            },
            linkerVorhof: {
                html: '<p>Linker Vorhof</p>',
                classes: ['anot-left'],
                anker: 'bottom-left',
                x: [
                    {
                        type: 'linear',
                        from: 59.5,
                        to: 54.5,
                        times: 7,
                        suffix: '%',
                    },
                    { type: 'repeat', value: null, times: 20 },
                    {
                        type: 'linear',
                        from: 53,
                        to: 60,
                        times: 7,
                        suffix: '%',
                    },
                ],
                y: ['50%'],
            },
            /*aortenKlappe: {
                html: '<p>Aorten Klappe</p>',
                classes: ['anot-left'],
                anker: 'bottom-left',
                x: [
                    {
                        type: 'linear',
                        from: 55,
                        to: 51,
                        times: 4,
                        suffix: '%',
                    },
                    { type: 'repeat', value: null, times: 27 },
                    {
                        type: 'linear',
                        from: 56,
                        to: 55,
                        times: 3,
                        suffix: '%',
                    },
                ],
                y: ['40%'],
            },*/
            linkeHerzkammer: {
                html: '<p>Linke Herzkammer</p>',
                classes: ['anot-left'],
                anker: 'bottom-left',
                x: [
                    {
                        type: 'linear',
                        from: 59,
                        to: 57,
                        times: 7,
                        suffix: '%',
                    },
                    { type: 'repeat', value: null, times: 25 },
                    {
                        type: 'linear',
                        from: 58,
                        to: 57,
                        times: 2,
                        suffix: '%',
                    },
                ],
                y: ['30%'],
            },
            /*herzScheidewand: {
                html: '<p>Herzscheidewand</p>',
                classes: ['anot-left'],
                anker: 'bottom-left',
                x: [
                    {
                        type: 'linear',
                        from: 55,
                        to: 58,
                        times: 3,
                        suffix: '%',
                    },
                    {
                        type: 'linear',
                        from: 58,
                        to: 60,
                        times: 5,
                        suffix: '%',
                    },
                    { type: 'repeat', value: null, times: 22 },
                    {
                        type: 'linear',
                        from: 50,
                        to: 55,
                        times: 4,
                        suffix: '%',
                    },
                ],
                y: ['16%'],
            },*/
        });

        new SpinViewer(heart, {
            imageUrl: heartSprite,
            rowCount: 34,
        }).on('change', (e) => {
            heartAnnotations.onFrameChange(e.frameIndex);
        });
        heartAnnotations.onFrameChange(0);
    }
});
