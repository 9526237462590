export default class Annotator {
    constructor(container, annotations) {
        if (!container instanceof HTMLElement) {
            throw new Error("container has to be a HTML Element.");
        }

        this.container = container;
        this.annotations = annotations;
        this.annotationsElements = {};
        let coords = {};
        let annotationElement = null;
        let annotKeys = Object.keys(this.annotations);

        this.container.style.position = 'relative';
        this.container.classList.add('annotated');

        for (let i = 0; i < annotKeys.length; i++) {
            if (!this.annotations[annotKeys[i]].hasOwnProperty('anker')) {
                this.annotations[annotKeys[i]].anker = 'bottom-left';
            }

            annotationElement = document.createElement('a');
            annotationElement.href = '#';
            annotationElement.classList.add('annoation-' + this.camelCase2kebabCase(annotKeys[i]));
            annotationElement.dataset.annotName = this.camelCase2kebabCase(annotKeys[i]);
            annotationElement.style.display = 'inline-block';
            annotationElement.style.position = 'absolute';
            annotationElement.style.zIndex = '100';

            if (this.annotations[annotKeys[i]].hasOwnProperty('events')) {
                let evtKeys = Object.keys(this.annotations[annotKeys[i]].events);
                for(let iEvtKey = 0; iEvtKey < evtKeys.length; iEvtKey++) {
                    annotationElement.addEventListener(evtKeys[iEvtKey], this.annotations[annotKeys[i]].events[evtKeys[iEvtKey]]);
                }
            }

            if (this.annotations[annotKeys[i]].hasOwnProperty('html')) {
                annotationElement.innerHTML = this.annotations[annotKeys[i]].html;
            }

            if (this.annotations[annotKeys[i]].hasOwnProperty('classes')) {
                annotationElement.classList.add(this.annotations[annotKeys[i]].classes);
            }
            this.annotationsElements[annotKeys[i]] = this.container.appendChild(annotationElement);
            coords = this.prepareCoordsForScreenSize(annotKeys[i], this.annotations[annotKeys[i]]);
        }

        this.expandAnnotsCoordArrays();
    }

    prepareCoordsForScreenSize(annotKey, annot) {
        let width = window.innerWidth;
        let breakPoints = [];
        let screenBreakPoint;

        if (annot.hasOwnProperty('breakpointCoords')) {
            breakPoints = Object.keys(annot.breakpointCoords);

            for (let i = 0; i < breakPoints.length; i++) {
                if (breakPoints[i] <= width) {
                    screenBreakPoint = breakPoints[i];
                } else {
                    i = breakPoints.length;
                }
            }

            this.annotations[annotKey].x = annot.breakpointCoords[screenBreakPoint].x;
            this.annotations[annotKey].y = annot.breakpointCoords[screenBreakPoint].y;
        }
    }


    expandAnnotsCoordArrays() {
        let annotKeys = Object.keys(this.annotations)

        for(let iAnnot = 0; iAnnot < annotKeys.length; iAnnot++) {
            if (this.annotations[annotKeys[iAnnot]].hasOwnProperty('x') && Array.isArray(this.annotations[annotKeys[iAnnot]].x)) {
                this.annotations[annotKeys[iAnnot]].x = this.annotations[annotKeys[iAnnot]].x
                    .map(this.expandAnnot)
                    .flat();
            }
            if (this.annotations[annotKeys[iAnnot]].hasOwnProperty('y') && Array.isArray(this.annotations[annotKeys[iAnnot]].y)) {
                this.annotations[annotKeys[iAnnot]].y = this.annotations[annotKeys[iAnnot]].y
                    .map(this.expandAnnot)
                    .flat();
            }
        }
    }

    camelCase2kebabCase(camelCaseString) {
        let kebabString = '';

        for (let i = 0; i < camelCaseString.length; i++) {
            if (camelCaseString.charCodeAt(i) > 64 && camelCaseString.charCodeAt(i) < 91) {
                kebabString += '-' + String.fromCharCode(camelCaseString.charCodeAt(i) + 32);
            } else {
                kebabString += camelCaseString[i];
            }
        }

        return kebabString;
    }

    onFrameChange(frameNum) {
        console.log(frameNum);
        let annotKeys = Object.keys(this.annotations);
        let coords = {};

        for (let i = 0; i < annotKeys.length; i++) {
            coords = this.getCoords(annotKeys[i], frameNum);

            if (coords.x == null || coords.y == null) {
                this.annotationsElements[annotKeys[i]].style.display = 'none';
            } else {
                switch (this.annotations[annotKeys[i]].anker) {
                    default:
                    case 'bottom-left':
                        this.annotationsElements[annotKeys[i]].style.left = coords.x;
                        this.annotationsElements[annotKeys[i]].style.bottom = coords.y;
                        break;
                    case 'bottom-right':
                        this.annotationsElements[annotKeys[i]].style.right = coords.x;
                        this.annotationsElements[annotKeys[i]].style.bottom = coords.y;
                        break;
                    case 'top-right':
                        this.annotationsElements[annotKeys[i]].style.right = coords.x;
                        this.annotationsElements[annotKeys[i]].style.top = coords.y;
                        break;
                    case 'top-left':
                        this.annotationsElements[annotKeys[i]].style.left = coords.x;
                        this.annotationsElements[annotKeys[i]].style.top = coords.y;
                        break;
                }
                this.annotationsElements[annotKeys[i]].style.display = 'inline-block';

            }
        }
    }

    getCoords(annotName, frameNum) {
        let x = null;
        let y = null;

        if (this.annotations[annotName].hasOwnProperty('x')) {
            switch (typeof this.annotations[annotName].x) {
                case 'object':
                    x = this.getCoordFromArray(this.annotations[annotName].x, frameNum);
                    break;
                case 'function':
                    x = this.annotations[annotName].x(frameNum);
                    break;
                default:
                    console.warn("Unknown type for annotation parameter x");
                    break;
            }
        }


        if (this.annotations[annotName].hasOwnProperty('y')) {
            switch (typeof this.annotations[annotName].y) {
                case 'object':
                    y = this.getCoordFromArray(this.annotations[annotName].y, frameNum);
                    break;
                case 'function':
                    y = this.annotations[annotName].y(frameNum);
                    break;
                default:
                    console.warn("Unknown type for annotation parameter y");
                    break;
            }
        } else {
            console.log("Using x for y since y isn't defined here.", this.annotations[annotName]);
            y = x;
        }

        return {x, y};
    }

    expandAnnot(annotToken) {
        let tokenMultiplied = [];
        if (typeof annotToken !== 'object') {
            return [annotToken];
        }

        if (annotToken && annotToken.hasOwnProperty('type')) {
            switch (annotToken.type) {
                case 'repeat':
                    while (annotToken.times >= 0) {
                        annotToken.times--;
                        tokenMultiplied.push(annotToken.value)
                    }
                    break;
                case 'linear':
                    let range = (Number(annotToken.to) - Number(annotToken.from)) / (Number(annotToken.times)-1);
                    for (let i = 0; i < annotToken.times; i++) {
                        tokenMultiplied.push(Number(annotToken.from) + (i*range));
                        if (annotToken.hasOwnProperty('prefix')) {
                            tokenMultiplied[tokenMultiplied.length-1] = annotToken.prefix + tokenMultiplied[tokenMultiplied.length-1];
                        }
                        if (annotToken.hasOwnProperty('suffix')) {
                            tokenMultiplied[tokenMultiplied.length-1] = tokenMultiplied[tokenMultiplied.length-1] + annotToken.suffix;
                        }
                    }
                    break;
            }
            return tokenMultiplied;
        } else {
            return annotToken;
        }
    }



    getCoordFromArray(coordArray, frameNum) {
        if (frameNum < coordArray.length-1) {
            return coordArray[frameNum];
        } else {
            return coordArray[coordArray.length-1];
        }
    }
}

