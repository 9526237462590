import {PanoViewer} from "@egjs/view360";

export default class Annotator360 {

    constructor(container, image, hotspots) {
        if (!container instanceof HTMLElement) {
            throw new Error('Container has to be a HTML Element.');
        }

        this.container = container;
        this.hotspots = hotspots;

        this.viewer = new PanoViewer(container, {
            image: image,
            useZoom: false,
            pitchRange: [-32, 33],
            yawRange: [-180, 180]
        }).on('ready', ((e) => {
            for(let i = 0; i < hotspots.length; i++) {
                let elem = document.createElement('a');
                if (hotspots[i].hasOwnProperty('href')) {
                    elem.href = hotspots[i].href;
                }
                if (hotspots[i].hasOwnProperty('title')) {
                    elem.title = hotspots[i].title;
                }
                elem.target = '_blank';
                elem.classList.add('hotspot-threesixty');
                hotspots[i].elem = elem;
                container.appendChild(hotspots[i].elem);
            }
            this.updateHotspots();
        })).on('viewChange', ((e) => {
            this.updateHotspots();
        }));
    }

    toRadian(deg) {
        return deg * Math.PI / 180;
    }

    getHFov(fov) {
        let rect = this.container.getBoundingClientRect(),
        width = rect.width,
        height = rect.height;

        return Math.atan(width / height * Math.tan(this.toRadian(fov) / 2)) / Math.PI * 360;
    }

    rotate(point, deg) {
        let rad = this.toRadian(deg),
            cos = Math.cos(rad),
            sin = Math.sin(rad);

        return [cos * point[0] - sin * point[1], sin * point[0] + cos * point[1]];
    }

    updateHotspots() {
        for (let i = 0; i < this.hotspots.length; i++) {
            this.setHotspotOffset(this.hotspots[i]);
        }
    }

    setHotspotOffset(hotspot) {
        let oyaw = this.viewer.getYaw(),
            opitch = this.viewer.getPitch(),
            deltaYaw = hotspot.yaw - oyaw,
            deltaPitch = hotspot.pitch - opitch;

        if (deltaYaw < -180) {
            deltaYaw += 360;
        } else if (deltaYaw > 180) {
            deltaYaw -= 360;
        }

        if (Math.abs(deltaYaw) > 90) {
            hotspot.elem.style.transform = "translate(-200px, 0px)";
            return;
        }

        let radYaw = this.toRadian(deltaYaw),
            radPitch = this.toRadian(deltaPitch),
            fov = this.viewer.getFov(),
            hfov = this.getHFov(fov),
            rx = Math.tan(this.toRadian(hfov) / 2),
            ry = Math.tan(this.toRadian(fov) / 2),
            point = [
                Math.tan(-radYaw) / rx,
                Math.tan(-radPitch) / ry,
            ];

        let left = this.viewer._width / 2 + point[0] * this.viewer._width / 2,
            top = this.viewer._height / 2 + point[1] * this.viewer._height / 2;

        hotspot.elem.style.transform = "translate(" + left + "px, " + top + "px) translate(-50%, -50%)";
    }
}