import hospitalSprite from '../../img/krankenhaus.jpg';
import Annotator360 from "../annotator360";

document.addEventListener('DOMContentLoaded', () => {
    let hospital = document.getElementById('hospital');
    if (hospital) {
        let hospitalAnnotator360 = new Annotator360(hospital, hospitalSprite,
            [
                { // Rote Matte, Füße, Bett
                    yaw: 36,
                    pitch: -15,
                    title: 'Antirutschmatte',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/taetigkeiten/kleine-hilfsmittel',
                },
                { // Blaue Matte, Körper, Bett
                    yaw: 12,
                    pitch: -18,
                    title: 'Gleitmatte',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/taetigkeiten/kleine-hilfsmittel',
                },
                { // Deskinfektionsmittel Spender
                    yaw: 136,
                    pitch: 3,
                    title: 'Händedesinfektionsmittel',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/zugehoerige-themen/hautschutz',
                },
                { // Frau Brust
                    yaw: 158,
                    pitch: 3,
                    title: 'Arbeitskleidung',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/zugehoerige-themen/arbeitskleidung',
                },
                { // Bettgestell
                    yaw: 35,
                    pitch: -24,
                    title: 'Pflegebetten',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/patientenzimmer/pflegebetten',
                },
                { // Über Kopfteil des Bettes
                    yaw: 40,
                    pitch: 10,
                    title: 'Beleuchtung',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/patientenzimmer/beleuchtung/',
                },
                { // Geschlossene Tür
                    yaw: -158,
                    pitch: 5,
                    title: 'WC & Duschen',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/patientenzimmer/wc-duschen',
                },
                { // Offene Eingangstür
                    yaw: 180,
                    pitch: 0,
                    title: 'Türen',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/patientenzimmer/tueren',
                },
                { // Telefon Beistelltisch
                    yaw: 80,
                    pitch: 0,
                    title: 'Elektrische Einrichtungen ',
                    href: 'https://www.sicheres-krankenhaus.de/patientenzimmer/patientenzimmer/elektrische-einrichtungen',
                },
            ]
        );
    }
});
