import swipeHelperImage from '../img/nice/360-icon.png'

export default class SwipeHelper {
    constructor(selector) {
        this.template = document.createElement('div');
        this.template.classList.add('swipe-helper');
        this.template.innerHTML = '<img src="' + swipeHelperImage + '" alt="Swipe icon" />';
        this.elements = document.querySelectorAll(selector);

        if (this.elements !== null && this.elements.length !== 0) {
            for (let i = 0; this.elements.length > i; i++) {
                this.createOverlay(this.elements[i]);
            }
        }
    }

    createOverlay(elem) {
        let clone = this.template.cloneNode(true);
        elem.appendChild(clone);
        elem.addEventListener('click', (eClick) => {
            eClick.target.addEventListener('transitionEnd', (eTrans) => {
                eTrans.target.remove();
            });

            eClick.target.style.opacity = '1.0';
            eClick.target.style.transition = 'opacity 2s';
            eClick.target.style.opacity = '0.0';
        });
    }
}

